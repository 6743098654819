import { building, DOMAIN_NAME } from "./main.js";
import { autocomplete } from "./autocomplete-module.js";
import { fetchMap } from "./map-module.js";
import { stateManager, setDisabled, resetField, startPreloader, stopPreloader } from "./form-manager.js";
import { selector } from "./selectors-module.js";
export default class Identification {

    constructor(kod_ruian, init_data, final_data, obec, momc, cast_obce, typ_so, ulice, cislo_domovni_typ, cislo_popisne, kat_uzemi, ciselna_rada, typ_parcely, parcelni_cislo,
	    tea_vodovod, tea_podlazi, tea_vytah, tea_dokonceno, tea_konstrukce, tea_obestaveno, tea_zastaveno,
	    tea_byty, tea_podlahova, tea_kanalizace, tea_plyn, tea_vytapeni) {
	this.kod_ruian = kod_ruian;
	this.init_data = init_data;
	this.final_data = final_data;
	this.obec = obec;
	this.momc = momc;
	this.cast_obce = cast_obce;
	this.typ_so = typ_so;
	this.ulice = ulice;
	this.cislo_domovni_typ = cislo_domovni_typ;
	this.cislo_popisne = cislo_popisne;
	this.kat_uzemi = kat_uzemi;
	this.ciselna_rada = ciselna_rada;
	this.typ_parcely = typ_parcely;
	this.parcelni_cislo = parcelni_cislo;
	this.tea_podlazi = tea_podlazi;
	this.tea_vytah = tea_vytah;
	this.tea_dokonceno = tea_dokonceno;
	this.tea_konstrukce = tea_konstrukce;
	this.tea_obestaveno = tea_obestaveno;
	this.tea_zastaveno = tea_zastaveno;
	this.tea_byty = tea_byty;
	this.tea_podlahova = tea_podlahova;
	this.tea_kanalizace = tea_kanalizace;
	this.tea_plyn = tea_plyn;
	this.tea_vodovod = tea_vodovod;
	this.tea_vytapeni = tea_vytapeni;

    }

    setObec(obec) {
	this.obec = obec;
    }

    setKodRuian(kod_ruian) {
	this.kod_ruian = kod_ruian;
    }

    setInitData(init_data) {
	this.init_data = init_data;

    }

    setFinalData(final_data) {
	this.final_data = final_data;
    }

    setMomc(momc) {
	this.momc = momc;
    }

    setCastObce(cast_obce) {
	this.cast_obce = cast_obce;
    }

    setTypSo(typ_so) {

	this.typ_so = typ_so;
    }

    setUlice(ulice) {
	this.ulice = ulice;
    }

    setCisloDomovniTyp(typ) {
	this.cislo_domovni_typ = typ;
    }

    setCisloPop(cislo_popisne) {
	this.cislo_popisne = cislo_popisne;
    }
    setKatUzemi(kat_uzemi) {
	this.kat_uzemi = kat_uzemi;
    }
    setCiselnaRada(cislena_rada) {
	this.ciselna_rada = cislena_rada;
    }
    setTypParcely(typ_parcely) {
	this.typ_parcely = typ_parcely;
    }
    setParcelniCislo(parcelni_cislo) {
	this.parcelni_cislo = parcelni_cislo;
    }
    setTeaVodovod(tea_vodovod) {
	this.tea_vodovod = tea_vodovod;
    }
    setTeaPodlazi(tea_podlazi) {
	this.tea_podlazi = tea_podlazi;
    }
    resetAllValues() {
	this.momc = undefined;
	this.resetAfterTypSoValues();
    }
    resetAfterTypSoValues() {
	this.kod_ruian = undefined;
	this.obec = undefined;
	this.typ_so = undefined;
	this.cast_obce = undefined;
	this.cislo_popisne = undefined;
	this.typ_parcely = undefined;
	this.final_data = undefined;
	this.kat_uzemi = undefined;
	this.ciselna_rada = undefined;
    }

    /**
     * Fetch data for relevant administrative unit 'obec' (also reset all previous values and trigger callback)
     * @param {string} obec - code of administrative unit 'obec'
     */
    getObec(obec, kod) {
	this.setObec(kod);
	if (kod) {
	    startPreloader()
	    fetch(DOMAIN_NAME + 'reklamaceRUIAN/getobecdata' + '?kod=' + kod)
		    .then((response) => response.json())
		    .then((data) => {
			stopPreloader();
			this.setInitData(data);
			stateManager.setState('obec');
		    })
		    .catch((error) => console.error('There was a problem with the request: ' + error));
	}
    }

    /**
     * get data about the administrative unit (city district) and put them into the selectbox.
     * sets the momc property of the 'this' object to the selected value
     */
    getMomc(selectorElement) {
	// let momc = this.init_data.momc;
	// let input = selectorElement;
	// let defaultOption = `<option value>Vyberte ze seznamu</option>`;
	// if (momc.length > 0) {
	//     for (let i = 0; i < momc.length; i++) {
	//         defaultOption += `<option value="${momc[i].KOD}">${momc[i].NAZEV}</option>`;
	//         input.innerHTML = defaultOption;
	//     }
	//     //eventlistener for click on option in selectBox
	//     if (!input.getAttribute('listener')) {
	//         input.addEventListener('change', (e) => {
	//             let momc_kod = e.target[e.target.selectedIndex].value;
	//             this.setMomc(momc_kod);
	//             stateManager.setState('momc');
	//         }, input.setAttribute('listener', true));
	//     }
	// } else {
	stateManager.setState('momc_without');
	// }
    }

    /**
     * add listener to select box. 
     * sets the typ_so property of the object to the selected value
     */
    getTypSo(selectorElement, selectorTextForReset, selectorAutocompleteForReset) {
	let input = selectorElement;
	if (!input.getAttribute('listener')) {
	    input.addEventListener('change', (e) => {
		this.resetAfterTypSoValues();
		if (selectorTextForReset && selectorAutocompleteForReset) {
		    resetField(selectorTextForReset, selectorAutocompleteForReset); //reset already filled field 'cislo popisne' if changed
		}
		let typ_so = e.target[e.target.selectedIndex].value
		this.setTypSo(typ_so);
		if (this.typ_so != 3) {
		    stateManager.setState('typ_so');
		} else {
		    stateManager.setState('typ_so_without_number');
		}

	    }, input.setAttribute('listener', true));
	}
    }

    /**
     * get data about the administrative unit (part of administrative unit) and put them into the selectbox.
     * sets the cast_obce property of the 'this' object to the selected value
     */
    getCastObce(selectorElement, selectorTextForReset, selectorAutocompleteForReset) {
	let data = this.init_data.cast_obce;
	let input = selectorElement;
	let defaultOption = `<option value>Vyberte ze seznamu</option>`;
	if (data.length > 0) {
	    for (let i = 0; i < data.length; i++) {
		if (this.init_data.cast_obce.length > 1) {
		    defaultOption += `<option value="${data[i].KOD}">${data[i].NAZEV}</option>`;
		    input.innerHTML = defaultOption;
		    if (!input.getAttribute('listener')) {
			input.addEventListener('change', (e) => {
			    if (selectorTextForReset && selectorAutocompleteForReset) {
				resetField(selectorTextForReset, selectorAutocompleteForReset);//if changed reset already filled field 'cislo popisne'
			    }
			    let cast = e.target[e.target.selectedIndex].value;
			    this.setCastObce(cast);
			    stateManager.setState('cast_obce');
			}, input.setAttribute('listener', true));
		    }
		} else {
		    defaultOption = `<option value="${data[i].KOD}">${data[i].NAZEV}</option>`;
		    input.innerHTML = defaultOption;
		    this.setCastObce(data[i].KOD);
		    stateManager.setState('cast_obce');
		}

	    }
	}
    }

    /**
     * get data about the street (part of administrative unit) and put them into the selectbox.
     * sets the ulice property of the 'this' object to the selected value
     */
    getUlice(textinput, selectInput) {
	let obec;
	if (this.obec) {
	    obec = this.obec;
	} else {
	    obec = this.final_data.forObject.obec_kod;
	}
	autocomplete(textinput, selectInput, 'getulice', setStreet.bind(this), '&obec=' + obec)
	function setStreet(ulice, kod) {
	    this.setUlice(kod);
	    stateManager.setState('ulice');
	}
    }

    /**
     * add listener to select box. 
     * sets the typ_so property of the object to the selected value
     */
    getCisloDomovniTyp(selectorElement, selectorTextForReset, selectorAutocompleteForReset) {
	let input = selectorElement;
	if (!input.getAttribute('listener')) {
	    input.addEventListener('change', (e) => {
		if (selectorTextForReset && selectorAutocompleteForReset) {
		    resetField(selectorTextForReset, selectorAutocompleteForReset); //reset already filled field 'cislo popisne' if changed
		}
		let typ = e.target[e.target.selectedIndex].value
		this.setTypSo(typ);
		stateManager.setState('cislo_domovni_typ');
	    }, input.setAttribute('listener', true));
	}
    }

    /**
     * get data about the house number (based on autocomplete) and put them into the selectbox.
     * sets the cislo_popisne property of the 'this' object to the selected value
     */
    getCisloPopisne() {
	if (selector.noStreetForNumberBox) {
	    selector.noStreetForNumberBox.classList.add('none');
	    selector.cisloPopisne.classList.remove('notFound');
	}
	autocomplete('.cislo_popisne', '.cislo_popisne_atc', 'getcislopop', setCP.bind(this), '&kod=' + this.cast_obce + '&typ=' + this.typ_so + '&form=' + stateManager.form_type)
	function setCP(cislo, kodRuian) {
	    this.setCisloPop(cislo);
	    this.setKodRuian(kodRuian);
	    if (stateManager.form_type === "ad-d") {
		selector.idRuian.value = this.kod_ruian;
	    } else if (stateManager.form_type === "ad-doc") {
		fetch(DOMAIN_NAME + 'reklamaceRUIAN/getambycode' + '?am_code=' + kodRuian)
			.then((response) => response.json())
			.then((data) => {
			    if (data.forObject.ulice_kod === null) {
				stateManager.setState('no_street_for_number');
			    } else {
				stateManager.setState('cislo_popisne');
			    }
			})
			.catch((error) => console.error('There was a problem with the request: ' + error));
	    } else {
		stateManager.setState('cislo_popisne');
	    }
	}
    }

    /**
     * get data about the parcel type.
     * sets the typ parcely property of the 'this' object to the selected value
     */
    getTypParcely() {
	let radio = document.querySelector('.radio');
	radio.addEventListener('click', (e) => {
	    this.setTypParcely(parseInt(e.target.value));
	    stateManager.setState('typ_parcely');
	})
    }

    /**
     * get data about the parcel number (based on autocomplete) and put them into the selectbox.
     * sets the parcelni_cislo property of the 'this' object to the selected value
     */
    getParcelniCislo(typ_par, textInput, selectInput) {
	let typParcely;
	let future_data = false;
	if (typ_par != undefined) {
	    typParcely = typ_par;
	} else {
	    typParcely = 'undefined';
	}

	if (stateManager.form_type === 'so-d') {
	    future_data = true;
	}


	autocomplete(textInput, selectInput, 'getparcelnicislo', setPC.bind(this), '&katuze=' + this.kat_uzemi + '&typparcely=' + typParcely + '&form=' + stateManager.form_type + '&future=' + future_data)
	// autocomplete('.parcelni_cislo', '.parcelni_cislo_atc', 'getparcelnicislo', setPC.bind(this), '&katuze=' + this.kat_uzemi + '&typparcely=' + typParcely + '&form=' + stateManager.form_type)
	function setPC(cislo, kodRuian) {
	    this.setParcelniCislo(cislo);
	    this.setKodRuian(kodRuian);
	    stateManager.setState('parcelni_cislo');
	}
    }

    /**
     * get data about the parcel number (based on autocomplete) and put them into the selectbox.
     * its only for solving a proposal of parcel number in so-dp type of form
     */
    getParcelniCisloProposal(typ_parcely, textInput, selectInput, katuze) {
	let typParcely;
	let future_data = true;
	if (typ_parcely != undefined) {
	    typParcely = typ_parcely;
	} else {
	    typParcely = 'undefined';
	}

	autocomplete(textInput, selectInput, 'getparcelnicislo', setPC, '&katuze=' + katuze + '&typparcely=' + typParcely + '&form=' + stateManager.form_type + '&future=' + future_data)
	function setPC(cislo, par_id) {
	    selector.parcelaId.value = par_id;
	}
    }

    /**
     * get data about the cadestral unit and put them into the selectbox.
     * sets the kat_uzemi property of the 'this' object to the selected value
     */
    getKatUzemi(selectorElement) {
	let data = this.init_data.katuze;
	let input = selectorElement;
	let defaultOption = `<option value>Vyberte ze seznamu</option>`;
	this.setCastObce(undefined);
	this.setCisloPop(undefined);
	if (data.length > 0) {
	    for (let i = 0; i < data.length; i++) {
		defaultOption += `<option value="${data[i].KOD}" data-cr="${data[i].CISELNA_RADA}">${data[i].NAZEV}</option>`;
		input.innerHTML = defaultOption;
		if (!input.getAttribute('listener')) {
		    input.addEventListener('change', (e) => {
			let katuze = e.target[e.target.selectedIndex].value;
			let rada = e.target[e.target.selectedIndex].getAttribute('data-cr');
			this.setKatUzemi(katuze);
			this.setCiselnaRada(rada);
			if (building.ciselna_rada === '1') {
			    fetch(DOMAIN_NAME + 'reklamaceRUIAN/getdruhcislovani' + '?katuze=' + katuze)
				    .then((response) => response.json())
				    .then((data) => {
					this.setTypParcely(data);
					if (data === 2) {
					    selector.typParcely2.checked = true;
					}
					if (data === 1) {
					    selector.typParcely1.checked = true;
					}
				    })
				    .then(() => {
					stateManager.setState('kat_uzemi');
				    })
				    .catch((error) => console.error('There was a problem with the request: ' + error));
			} else {
			    stateManager.setState('kat_uzemi');
			}

		    }, input.setAttribute('listener', true));
		}
	    }
	}
    }

    /**
     * fetch final data based on code ruian
     */
    getFinalDataByCode(code) {
	
	startPreloader();
	if (stateManager.form_type.startsWith("so")) {
	    fetch(DOMAIN_NAME + 'reklamaceRUIAN/getsobycode' + '?so_code=' + code)
		    .then((response) => response.json())
		    .then((data) => {
			stopPreloader();
			
			if (Object.keys(data.forObject).length) {
			    if ((stateManager.form_type === 'so-dc' || stateManager.form_type === 'so-ta') && data.forObject.cislo_popisne == "") {
				stateManager.setState('not_cislo_popisne');
			    } else if ((stateManager.form_type === 'so-td' && data.forObject.typ_so != 3)) {
				stateManager.setState('is_cislo_popisne');
			    } else {
				this.afterFinalData(data)
			    }
			} else {
			    stateManager.setState('not_found');
			}
		    })
		    .catch((error) => console.error('There was a problem with the request: ' + error));
	} else if (stateManager.form_type.startsWith("ad")) {
	    fetch(DOMAIN_NAME + 'reklamaceRUIAN/getambycode' + '?am_code=' + code)
		    .then((response) => response.json())
		    .then((data) => {
			stopPreloader()
			if (Object.keys(data.forObject).length) {
			    if (stateManager.form_type === 'ad-zu' && data.forObec.ulice.length === 0) {
				stateManager.setState('no_street_delete');
			    } else if (stateManager.form_type === 'ad-doc' && data.forObject.ulice_kod == null) {
				stateManager.setState('no_street_delete');
			    } else {
				this.afterFinalData(data)
			    }
			} else {
			    stateManager.setState('not_found');
			}
		    })
		    .catch((error) => console.error('There was a problem with the request: ' + error));
	} else if (stateManager.form_type === 'obu') {
	    fetch(DOMAIN_NAME + 'reklamaceISKN/getbudbycode' + '?bud_code=' + code)
		    .then((response) => response.json())
		    .then((data) => {
			stopPreloader()
			if (Object.keys(data.forObject).length) {
			    this.afterFinalData(data)
			} else {
			    stateManager.setState('not_found');
			}
		    })
		    .catch((error) => console.error('There was a problem with the request: ' + error));
	} else if (stateManager.form_type === 'pdb') {
	    fetch(DOMAIN_NAME + 'reklamaceISKN/getparbycode' + '?par_code=' + code)
		    .then((response) => response.json())
		    .then((data) => {
			stopPreloader()
			if (Object.keys(data.forObject).length) {
			    this.afterFinalData(data)
			} else {
			    stateManager.setState('not_found');
			}
		    })
		    .catch((error) => console.error('There was a problem with the request: ' + error));
	} else {
	    fetch(DOMAIN_NAME + 'reklamaceRUIAN/getulicebycode' + '?ulice_code=' + code)
		    .then((response) => response.json())
		    .then((data) => {
			stopPreloader()
			if (Object.keys(data.forObject).length) {
			    if (stateManager.form_type.startsWith("ul") && stateManager.form_type != 'ul-d') {
				selector.obecKod.value = data.forObject.obec_kod;
			    }
			    this.afterFinalData(data)
			} else {
			    stateManager.setState('not_found');
			}
		    })
		    .catch((error) => console.error('There was a problem with the request: ' + error));
	}
    }

    /**
     * facade for callback after fetching the final data
     */
    afterFinalData(data) {
	this.setFinalData(data);
	if (stateManager.form_type === 'ad-du' && this.final_data.forObec.ulice.length < 1) {
	    selector.obecTextInput.value = this.final_data.forObject.obec;
	    stateManager.setState('no_street');
	} else {
	    if (stateManager.form_type.startsWith('so')) {
		fetchMap('so');
		if (stateManager.form_type === 'so-ta' && this.final_data.forObject.cislo_popisne.includes(",")) {
		    stateManager.setState('vice_cislo_popisne');
		}
	    }
	    if (stateManager.form_type.startsWith('ad')) {
		fetchMap('ad');
	    }
	    if (stateManager.form_type === 'obu') {
		fetchMap('obu');
	    }
	    if (stateManager.form_type === 'pdb') {
		fetchMap('pdb');
	    }
	    if (stateManager.form_type.startsWith('ul')) {
		fetchMap('ul');
	    }
	    this.fillBoxesWithFinalData();
	    stateManager.setState('final_data');

	}
    }

    /**
     * fill boxes in form identation section with responsed final data about the object
     */
    fillBoxesWithFinalData() {
	let setOption = (code, text) => {
	    return `<option value="${code}">${text}</option>`;
	}
	//fill boxes with data
	selector.obecTextInput.value = this.final_data.forObject.obec;
	if (this.final_data.forObject.momc) {
	    if (this.final_data.forObject.momc != undefined) {
		selector.momc.innerHTML = setOption(this.final_data.forObject.momc_kod, this.final_data.forObject.momc);
	    } else {
		selector.momc.innerText = ""
	    }
	}
	if (selector.typSo) {
	    selector.typSo.value = this.final_data.forObject.typ_so;
	}
	if (this.final_data.forObject.cast_obce) {
	    if (this.final_data.forObject.cast_obce != undefined) {
		selector.castObce.innerHTML = setOption(this.final_data.forObject.cast_obce_kod, this.final_data.forObject.cast_obce);
	    } else {
		selector.castObce.innerText = ""
	    }
	}
	if (selector.ulice) {
	    selector.ulice.value = this.final_data.forObject.ulice_nazev;
	}
	if (stateManager.form_type.startsWith('so')) {
	    selector.cisloPopisne.value = this.final_data.forObject.cislo_popisne;
	} else if (stateManager.form_type.startsWith('ad')) {
	    selector.cisloPopisne.value = this.final_data.forObject.cislo_domovni_vse;
	}
	if (selector.cisloPopisnePuvodni) {
	    selector.cisloPopisnePuvodni.value = this.final_data.forObject.cislo_popisne;
	}

	selector.idRuian.value = this.final_data.forObject.kodruian;
	selector.kodRuianInput.value = this.final_data.forObject.kodruian;
	if (stateManager.form_type.startsWith('so') || stateManager.form_type === 'obu' || stateManager.form_type === 'pdb') {
	    if (this.final_data.forObject.katuze_kod != null) {
		selector.katUzemi.innerHTML = setOption(this.final_data.forObject.katuze.kod, this.final_data.forObject.katuze);
	    }
	    if (selector.parcelaId) {
		selector.parcelaId.value = this.final_data.forObject.parcela_id;
	    }
	    selector.parcelniCislo.value = this.final_data.forObject.parcela_cislo;
	    if (this.final_data.forObject.parcela_typ === 1) {
		selector.typParcely1.checked = true;
	    } else {
		selector.typParcely2.checked = true;
	    }
	}
	if (selector.zpusobVyuziti) {
	    selector.zpusobVyuziti.value = this.final_data.forObject.zpvyuzso;
	}
	if (selector.psc) {
	    selector.psc.value = this.final_data.forObject.psc
	}
	if (selector.defb_x) {
	    selector.defb_x.value = this.final_data.forObject.defbx
	}
	if (selector.defb_y) {
	    selector.defb_y.value = this.final_data.forObject.defby
	}
	if (selector.defb_x_hasici) {
	    selector.defb_x_hasici.value = this.final_data.forObject.defbx_hasici
	}
	if (selector.defb_y_hasici) {
	    selector.defb_y_hasici.value = this.final_data.forObject.defby_hasici
	}
	if (selector.defb_x_zzs) {
	    selector.defb_x_zzs.value = this.final_data.forObject.defbx_zzs
	}
	if (selector.defb_y_zzs) {
	    selector.defb_y_zzs.value = this.final_data.forObject.defby_zzs
	}
	if (selector.tea_podlazi) {
	    selector.tea_podlazi.value = this.final_data.forObject.pocet_podlazi
	}
	if (selector.tea_vodovod) {
	    selector.tea_vodovod.value = this.final_data.forObject.vodovod_kod
	}
	if (selector.tea_dokonceno) {
	    selector.tea_dokonceno.value = this.final_data.forObject.dokonceni
	}
	if (selector.tea_vytah) {
	    selector.tea_vytah.value = this.final_data.forObject.vytah_kod
	}
	if (selector.tea_konstrukce) {
	    selector.tea_konstrukce.value = this.final_data.forObject.konstrukce_kod
	}
	if (selector.tea_obestaveno) {
	    selector.tea_obestaveno.value = this.final_data.forObject.obestaveny_prostor
	}
	if (selector.tea_podlahova) {
	    selector.tea_podlahova.value = this.final_data.forObject.podlahova_plocha
	}
	if (selector.tea_zastaveno) {
	    selector.tea_zastaveno.value = this.final_data.forObject.zastavena_plocha
	}
	if (selector.tea_byty) {
	    selector.tea_byty.value = this.final_data.forObject.pocet_bytu
	}
	if (selector.tea_kanalizace) {
	    selector.tea_kanalizace.value = this.final_data.forObject.kanalizace_kod
	}
	if (selector.tea_plyn) {
	    selector.tea_plyn.value = this.final_data.forObject.plyn_kod
	}
	if (selector.tea_vytapeni) {
	    selector.tea_vytapeni.value = this.final_data.forObject.vytapeni_kod
	}
	if (selector.cisloDomovniTyp) {
	    selector.cisloDomovniTyp.value = this.final_data.forObject.typ_so;
	}

	//add .lock class for all inputs (for styling purpose)
	this.addLockAttribute();
    }

    /**
     * add .lock class to all input in identificatin section. 
     * This is for styling purpose only.
     */
    addLockAttribute() {
	let wrap = document.querySelector('.identification');
	let selects = wrap.querySelectorAll('select');
	let text = wrap.querySelectorAll('input');
	let all = Array.from(selects).concat(Array.from(text).concat(selector.kodRuianInput));
	all.forEach(input => {
	    input.classList.add('lock');
	    // input.setAttribute('readonly');
	    setDisabled(input);
	});
    }
}

